.appointment-list-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  .table-wrapper {
    flex: 1;
    overflow: auto;
  }
}

.section-wrapper {
  margin-bottom: 20px;
}