.input-group {
  margin-bottom: 10px;
}

.input {
  margin-bottom: 10px;
}

.top-section {
  display: flex;
  flex-direction: column;
}
