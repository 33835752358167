.twilio-view {
  display: flex;
  flex: 1;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .info-section {
    margin-left: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .medias {
    width: 50%;
    max-width: 450px;
  }

  @media only screen and (max-width: 992px) {
    .info-section {
      margin-left: 0;
    }

    .medias {
      width: 100%;
      max-width: 100%;
    }
  }

  .btn-section {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.twilio-section {
  margin: 15px 0;
}

.cart-section-top {
  display: flex;
}

#remote-media {
  min-width: 300px;

  video {
    width: 100%;
    height: auto;
  }
}