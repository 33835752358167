.order-manage-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dateRangePicker {
  margin-bottom: 10px;
}

.checkbox-custom {
  label {
    font-weight: 700;
  }
}

.checkboxGroup-custom {
  flex-wrap: wrap;
  margin: 5px 0 10px 0;
}

.search-wrapper {
  margin: 5px 0 10px 0;
  display: flex;
  flex-direction: column;
}

.subtitle-wrapper-filter {
  display: block;

  .filters-expander {
    display: none;
    width: 15px;
    height: 15px;
    background-color: #009244;
    border-radius: 999px;

    img {
      width: 100%;
      margin-top: 1px;
      transition: transform 0.3s;
    }
  }
}

@media only screen and (max-width: 992px) {
  .filter-wrapper {
    opacity: 1;
    max-height: 800px;
    transition: max-height 0.3s ease-in-out, opacity 0.6s linear;
    -webkit-transition: max-height 0.3s ease-in-out, opacity 0.6s linear;
    -moz-transition: max-height 0.3s ease-in-out, opacity 0.6s linear;
    -o-transition: max-height 0.3s ease-in-out, opacity 0.6s linear;
    -ms-transition: max-height 0.3s ease-in-out, opacity 0.6s linear;

    &.reduced {
      opacity: 0;
      max-height: 0;
    }
  }

  .subtitle-wrapper-filter {
    display: flex;
    align-items: center;
    gap: 8px;

    .filters-expander {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1px;
      box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0 1px 3px -1px;

      &.expanded {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }
}