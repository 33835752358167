.sidebar-wrapper {
  height: 100vh;
  display: flex;
  width: 240px;
}

.mobile-banner {
  display: none;

  .menu-btn {
    background-color: transparent;
    display: flex;
    align-items: center;

    .icon {
      height: 100%;
    }
  }

}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.subtitle {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 5px 0;
  padding: 0;
}

.filter-title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.btn-main {
  background-color: #0f9119 !important;
  color: white !important;
  font-weight: 600 !important;
  margin: 5px 0 !important;
  max-width: 350px;
  width: 100%;
}

.main-section {
  flex: 1;
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
}

.default-input{
  width: 100%;
  max-width: 400px;
  margin-bottom: 10px;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .main-section{
    height: calc(100vh - 30px);
  }

  .sidebar-wrapper {
    position: fixed;
    top: 30px;
    left: -240px;
    z-index: 99;
    transition: left 0.3s ease;
  }

  .mobile-banner {
    display: flex;
    width: 100%;
    background-color: #e5e5e5;
    justify-content: space-between;
    padding: 5px;
    height: 30px;
  }

  .title {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: normal;
    margin: 10px 0;
    padding: 0;
  }

  .subtitle {
    font-size: 1rem;
    font-weight: 600;
    margin: 5px 0;
    padding: 0;
  }

  .filter-title {
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
}