th,
td {
  padding: 8px;
  padding-right: 10px;
  padding-bottom: 3px;
  padding-top: 3px;
  border-top-width: 0;
  border-left-width: 0;
  vertical-align: top;
}
